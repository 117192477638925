import React, { useCallback, useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";

import pitauIcon from "./assets/logos/pitauicon-white.png";
import pitauLogo from "./assets/logos/pitaulogo.png";

import stanfordCampus from "./assets/photos/stanfordcampus.jpeg";

import { Row, Col, Card, Button } from "antd";

// import introVideo from "./assets/videos/compressed_intro.mp4";
import background from "./assets/photos/background.jpg";
import "./App.css";

import AlumniPic from "./assets/photos/akpsi-group-pic.jpg";
import UpsilonPic from "./assets/photos/upsilon.JPG";

// import { prizes } from './prizes';

import {
  AiFillGithub,
  AiFillInstagram,
  AiOutlineFacebook,
  AiOutlineMedium,
  AiOutlineTwitter,
  AiFillStar,
} from "react-icons/ai";

function App() {
  const { Meta } = Card;
  // FAQ Accordian

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1000);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1000);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => {
      window.removeEventListener("resize", updateMedia);
    };
  }, []);

  return true ? (
    <div className="overflow-hidden scroll-smooth">
      <section
        class="lg:h-[90vh] md:pt-20 flex w-screen lg:pl-40 lg:pr-40 md:pl-20 md:pr-20 pl-0 pr-0 flex-col justify-center items-center"
        id="home"
      >
        {/* <div class="scale-90 w-11/12 mx-auto aspect-w-16 aspect-h-9 overflow-hidden rounded-2xl absolute top-[2rem] bottom-[5rem] bottom-5 transform -translate-x-[2%]">
          <div class="rounded-2xl background-block left-0"></div>
        </div> */}

        {/* working video, getting rid of now because we don't have any videos lol */}
        {/* <div class="scale-90 w-11/12 mx-auto aspect-w-16 aspect-h-9 overflow-hidden rounded-2xl absolute top-[2rem] left-1/2 transform -translate-x-[50%]">
          <img
            src={background}
            // autoPlay
            // muted
            // loop
            class="brightness-[0.4] object-cover w-full h-full"
          />
        </div> */}

        <div
          className="flex flex-row items-center w-full justify-content: start md:rounded-2xl bg-cover bg-center bg-no-repeat bg-fixed h-[90vh] relative z-10 px-8 md:px-20"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${background})`,
          }}
        >
          <div className="z-auto">
            <div className="inline-block">
              {/* <p className="font-semibold xl:text-white lg:text-white md:text-white text-white mb-1 font-display xl:text-lg lg:text-md text-lg">
                Welcome to Stanford Alpha Kappa Psi
              </p> */}
              <img alt="logo" src={pitauLogo} style={{ width: 150 }} />
              {/* <div class="h-[2px] bg-gradient-to-r xl:bg-white lg:bg-white md:bg-white bg-white rounded-full mb-4"></div> */}
            </div>

            <p className="text-4xl md:text-6xl mb-4 text-white font-RubikBold">
              Stanford's Premier Co-ed Pre-professional Business Fraternity
            </p>
          </div>
        </div>
      </section>

      <section className="w-full pt-40  xl:pl-40 xl:pr-40 lg:pl-10 lg:pr-10 md:pl-20 md:pr-20 pl-4 pr-4 flex flex-col relative justify-center items-center mb-20">
        <div className="container mx-auto lg:px-20 text-center">
          <h1
            className="text-center font-Raleway font-extrabold mb-4"
            style={{
              color: "#232323",
              fontSize: "36px",
              margin: "auto",
              marginBottom: "20px",
            }}
          >
            Since 1904, AKPsi has been the nation's largest & oldest business fraternity.
          </h1>
          <p
            className="description text-center font-Raleway text-lg"
            style={{ color: "#585858", margin: "auto" }}
          >
            Alpha Kappa Psi was founded on the principles of educating its members and the public to
            appreciate and demand higher ideals in business and to further the individual welfare of
            members during college and beyond. Alpha Kappa Psi is much more than just another
            organization or club—it is a unique, prestigious association of students, professors,
            graduates and professionals with common interests and goals. Alpha Kappa Psi provides
            valuable educational, friendship and networking opportunities.
          </p>

          <img
            src={pitauIcon}
            className="absolute"
            alt="Logo"
            style={{
              width: 600,
              maxWidth: "70%",
              opacity: 0.8,
              bottom: "10%",
              margin: "auto",
              left: 0,
              right: 0,
              zIndex: -1,
            }}
          />

          <h1
            className="text-center font-Raleway font-extrabold mt-20 mb-8 mx-auto bg-clip-text"
            style={{
              width: "80%",
              color: "#232323",
              fontSize: "36px",
              lineHeight: "1.2",
            }}
          >
            The Pi Tau Chapter
          </h1>
          <p
            className="description text-center font-Raleway pb-20 text-lg"
            style={{ color: "#585858", margin: "auto" }}
          >
            Founded in 1999, the Pi Tau Chapter of AKPsi is the premier co-ed business fraternity in
            the Bay Area. We are a group of students interested in business-related careers and
            teach our members professional skills. Members of the Pi Tau chapter are multi-faceted
            and come from diverse backgrounds and majors, including engineering, humanities and
            sciences.
          </p>

          <button
            onClick={() => window.open("https://forms.gle/ceXdauWEywgdArQT6", "_blank")}
            class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800"
          >
            <span class="relative px-10 py-3 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-lg text-2xl group-hover:bg-opacity-0">
              Rush AKPsi
            </span>
          </button>
        </div>
      </section>

      <section className="w-full lg:pl-40  lg:pr-40 px-8 flex flex-col relative pb-16" id="tracks">
        <Row gutter={[24, 24]} className="h-full">
          <Col xs={{ span: 24 }} md={{ span: 12 }} className="h-full">
            <Link to="/alumni" style={{ cursor: "pointer", height: "100%" }} className="h-full">
              <Card
                hoverable={true}
                bordered={false}
                cover={
                  <img
                    alt="example"
                    src={AlumniPic}
                    style={{ objectFit: "cover", height: "400px" }}
                  />
                }
                className="h-full"
                style={{ display: "flex", flexDirection: "column", height: "100%" }}
              >
                <Meta style={{ textAlign: "center" }} title="Alumni" />
              </Card>
            </Link>
          </Col>
          <Col span={12} xs={{ span: 24 }} md={{ span: 12 }} className="h-full">
            <Link
              to="/brotherhood"
              style={{ cursor: "pointer", height: "100%" }}
              className="h-full"
            >
              <Card
                hoverable={true}
                bordered={false}
                cover={
                  <img
                    alt="example"
                    src={UpsilonPic}
                    style={{ objectFit: "cover", height: "400px" }}
                  />
                }
                className="h-full"
                style={{ display: "flex", flexDirection: "column", height: "100%" }}
              >
                <Meta style={{ textAlign: "center" }} title="Meet the Actives" />
              </Card>
            </Link>
          </Col>
        </Row>
      </section>

      <section className="w-full px-8 lg:pl-40 lg:pr-40 relative pb-16">
        <div
          className="text-center flex flex-col mx-auto p-4 md:p-16"
          style={{
            height: "50vh",
            backgroundImage: `linear-gradient(rgba(17, 43, 84,0.7), rgba(17, 43, 84,0.7)), url(${stanfordCampus})`,
            borderRadius: 20 /* Rounded corners */,
            justifyContent: "space-evenly",
            gap: 0,
            backgroundPosition: "center",
          }}
        >
          <h1 className="xl:text-5xl lg:text-4xl text-4xl mb-4 font-RubikBold text-white">
            Join the Brotherhood.
          </h1>
          <p
            className="description text-center font-Raleway font-bold text-lg"
            style={{ color: "#FFFFFF" }}
          >
            Follow us on Facebook and Instagram to keep up with the latest updates and schedule for
            our upcoming rush this spring! Learn more about our brothers and how AKPsi can help you
            grow your professional career and make the most of your college experience.
          </p>
          <div className="flex flex-row mx-auto">
            {/* <a href="https://medium.com/@hackwithtrees" target="_blank">
              <AiOutlineMedium
                className="mr-4 text-gray-200 xl:text-gray-200 lg:text-gray-200 md:text-gray-200"
                size={30}
              />
            </a> */}
            <a href="https://www.facebook.com/StanfordAKPsi/" target="_blank">
              <AiOutlineFacebook
                className="mr-4 text-gray-200 xl:text-gray-200 lg:text-gray-200 md:text-gray-200"
                size={30}
              />
            </a>

            <a href="https://www.instagram.com/stanford.akpsi/?hl=en" target="_blank">
              <AiFillInstagram
                className="mr-4 text-gray-200 xl:text-gray-200 lg:text-gray-200 md:text-gray-200"
                size={30}
              />
            </a>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <div className="mt-10 z-10" style={{ textAlign: "center", height: "100vh", paddingTop: "20%" }}>
      {"Use a desktop device for this site :("}
      <img
        src={pitauIcon}
        className="z-0"
        alt="AKPsi Logo"
        style={{ width: "50%", opacity: 0.8, top: -4, margin: "auto", marginTop: "10%" }}
      />
    </div>
  );
}

export default App;
