import React from "react";

export const Rush = () => {
  return (
    <section className="w-full px-8 lg:pl-40 lg:pr-40 flex flex-col relative lg:pb-16 pt-20">
      <h1
        className="text-center font-Raleway font-extrabold mb-8 bg-clip-text text-4xl"
        style={{ color: "#232323" }}
      >
        Rush
      </h1>

      <p className="">
        For updates on Spring 2025 rush, fill out our interest form{" "}
        <a
          className="hover-link"
          href="https://forms.gle/ceXdauWEywgdArQT6"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
      <img
        src={require("./assets/rush flyer spring 2025.jpg")}
        className="w-full md:w-1/2 pt-8 m-auto"
      />
    </section>
  );
};
